<template>
  <div>

    {{
      $companyConfiguration.card.getAvailablePeriods()[row.period]
        ? $companyConfiguration.card.getAvailablePeriods()[row.period]
        : ''
    }}

  </div>
</template>

<script>

export default {
  name: "period-column-template",
  components: {},

  props: {
    row: {type: Object, required: true}
  },

  data() {
    return {}
  },
  mounted() {
  },
  watch: {},
  computed: {},
  methods: {},

}
</script>

<style>
.table-router-link {
  text-decoration: none;
}
</style>